// import { createRouter, createWebHashHistory } from 'vue-router'
// import type { RouteRecordRaw } from 'vue-router'

// const routes: RouteRecordRaw[] = [
//   {
//     path: '/',
//     name: '首页',
//     component: () => import('./views/center')
//   },
//   {
//     path: '/user',
//     name: '个人中心',
//     component: () => import('./views/user')
//   }
// ]

// export const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// })

// export default router

import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  ...useHomeRouter(),
  ...usePersonalCenterRouter(),
  ...useDiscussRouter()
]

function useHomeRouter (): RouteRecordRaw[] {
  return [
    {
      path: '/',
      name: '首页',
      component: () => import('./views/center'),
      meta: {
        isMenu: true, // 是否菜单
        noLogin: true, // 不登录是否显示
        menuIcon: 'home'
      }
    }
  ]
}

function usePersonalCenterRouter (): RouteRecordRaw[] {
  return [
    {
      path: '/personal',
      name: '个人中心',
      component: () => import('./views/user'),
      meta: {
        isMenu: true,
        noLogin: false,
        menuIcon: 'user'
      }
    }
  ]
}

function useDiscussRouter (): RouteRecordRaw[] {
  return [
    {
      path: '/discuss',
      name: '查阅论坛',
      component: () => import('./views/discuss'),
      meta: {
        isMenu: false,
        noLogin: true,
        menuIcon: 'user'
      }
    }
  ]
}

export const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
