
// 后端接口地址
// export const BASE_SERVER = 'http://192.168.1.236:2828'
export const BASE_SERVER = 'http://121.41.104.213:2828'

// 资源文件地址
// export const RESOURCES_SERVER = 'http://192.168.1.236:2728/'
export const RESOURCES_SERVER = 'http://121.41.104.213:2728/'
// 登录页面地址
// export const LOGIN_SERVER = 'http://192.168.1.236:3000/'
export const LOGIN_SERVER = 'http://121.41.104.213:8888/'

// 默认图片
export const DEFAULT_IMAGE = '[{"path":"2022/c0f47826-3d9e-4791-b28d-1909d81f0b0f.jpeg","name":"3.jpeg"}]'
