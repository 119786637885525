/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { commonInstance, parseResult } from './index'

import type { Result } from './index'

export interface User {
  id: string,
  username: string,
  name: string,
  head: string,
  birthday: Date
}

export async function getUserList () {
  return parseResult(await commonInstance.get<Result<User[]>>('/user', { disableCache: true }), [])
}

export async function getUser () {
  return parseResult(await commonInstance.get<Result<User>>('/user/current', { disableCache: true }), null)
}

export async function updateUser (user: User): Promise<boolean> {
  return parseResult(
    await commonInstance.put<Result<boolean>>('/user', user, {
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    false
  )
}
