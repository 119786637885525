import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { install } from 'vexip-ui'
import 'vexip-ui/dist/style.css'
import './icon'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIons from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App)
app.use(router).use(ElementPlus, { locale: zhCn }).use(install)
for (const [key, component] of Object.entries(ElIons)) {
  app.component(key, component)
}
app.mount('#app')
