import { Icon } from 'vexip-ui'

Icon.register({
  myHome: {
    width: 1024,
    height: 1024,
    paths: [
      {
        d: 'M937.067069 482.335377 556.719504 106.839307c-1.89107-2.251274-6.123464-6.173606-11.997242-10.048867-9.889231-6.522554-21.093411-10.486842-33.388435-10.486842-13.137205 0-24.610514 3.984754-34.245965 10.590196-5.826705 3.997034-9.844206 8.076956-12.117992 11.117199L85.643566 482.381425c-14.653745 14.434757-14.653745 37.890982 0 52.358485 14.538111 14.380522 33.883715 8.316409 50.366108-7.919367L161.532977 501.587859l350.847693-339.869664 374.329501 368.073007c20.077268 13.223163 37.773302 17.377786 50.358945 4.946662C951.720813 520.273431 951.720813 496.801856 937.067069 482.335377z'
      },
      {
        d: 'M793.007045 462.046285c-17.391089 0-31.567973 13.938454-31.634488 31.236422l0 0.085958 0 0.089028 0 350.143659c0 17.416671-14.371312 31.602765-32.119535 31.602765l-84.129072 0 0-192.166671c0-49.818639-40.803311-90.111321-91.14486-90.111321l-85.268012 0c-50.326199 0-91.143836 40.300868-91.143836 90.111321l0 192.166671L293.437146 875.204116c-17.750269 0-32.119535-14.186094-32.119535-31.602765L261.317611 493.391177c-0.033769-17.355273-14.21884-31.343869-31.611975-31.343869-17.418718 0-31.589462 13.96506-31.658024 31.302937l0 354.429265c0 49.844222 40.808428 90.133833 91.14486 90.133833l141.253094 0 10.389628 0 0-10.391674 0-240.262062c0-17.410532 14.365172-31.580253 32.119535-31.580253l76.801177 0c17.756409 0 32.119535 14.169721 32.119535 31.580253l0 240.262062 0 10.391674 10.391674 0 141.253094 0c50.321082 0 91.14486-40.297798 91.14486-90.133833L824.665069 493.322615C824.527946 475.958132 810.380738 462.046285 793.007045 462.046285z'
      }
    ]
  }
})
