/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios from 'axios'

import { deepClone } from '@/utils/deep-clone'
import { BASE_SERVER, LOGIN_SERVER } from './config'
import { addSeconds, differenceMilliseconds } from '@/utils/date'
import { Message } from 'vexip-ui'

import type { AxiosRequestConfig, AxiosAdapter } from 'axios'

export type Replace<T, K> = {
  [P in keyof T | keyof K]: P extends keyof K ? K[P] : P extends keyof T ? T[P] : never
}

export type Result<T> = {
  status: 0 | 1,
  message: string,
  data: T
}

export const commonInstance = createAxiosInstance({
  baseURL: BASE_SERVER,
  withCredentials: true
})

const defaultAdapter = Axios.defaults.adapter as AxiosAdapter

const cacheMap = new Map()

function cacheResponse (config: AxiosRequestConfig) {
  const token = `${config.method}:${config.url}`
  const current = Date.now()

  let { response, timeout } = cacheMap.get(token) ?? {}

  if (!response || differenceMilliseconds(current, timeout) < 0) {
    response = (async () => {
      try {
        return await defaultAdapter(config)
      } catch (error) {
        return Promise.reject(error)
      }
    })()

    cacheMap.set(token, {
      response,
      timeout: addSeconds(current, config.validityTime ?? 600)
    })
  }

  return response.then((data: any) => deepClone(data))
}

export function createAxiosInstance (options: AxiosRequestConfig) {
  const instance = Axios.create(options)

  instance.interceptors.request.use(
    config => {
      if (config.method?.toUpperCase() === 'GET' && !config.disableCache) {
        config.adapter = cacheResponse
      }

      return config
    },
    error => Promise.reject(error)
  )

  instance.interceptors.response.use(
    response => {
      if (response.status < 200 && response.status > 300) {
        // 与接口响应中的 status 是同一个属性
        return Promise.resolve({ status: 0 })
      }

      const data = response?.data
      const responseValue = data?.data

      if (responseValue === 2000) {
        if (!response.request.responseURL.includes('/user/current')) {
          Message.error('登录失效，请重新登录')
          window.location.href = LOGIN_SERVER
          return Promise.resolve({ status: 0 })
        }
      }

      if (responseValue === 3000) {
        Message.error('无权限访问')
        return Promise.resolve(response.data)
      }

      return Promise.resolve(data)
    },
    error => {
      // 4** 或 5**触发
      // Message.error(error?.response?.status)
      // if (error?.config?.url !== '/user/current') {
      // }
      Promise.reject(error)
    }
  )

  return instance
}

export function parseResult<T> (result: Result<T>, defaultValue: T): T
export function parseResult<T, K> (result: Result<K>, defaultValue: K, callback: (data: K) => T): T
export function parseResult<T, K> (
  result: Result<K>,
  defaultValue: K,
  callback?: (data: K) => T
): K | T {
  if ('status' in result && result.status) {
    if (typeof callback === 'function') {
      return callback(result.data ?? defaultValue)
    }

    return result.data
  }

  return defaultValue
}

export function isValidKey (
  key: string | number | symbol,
  // eslint-disable-next-line @typescript-eslint/ban-types
  object: object
): key is keyof typeof object {
  return key in object
}
